<template>
  <Transition name="fade">
    <aside
      v-if="showBackdropWithAppsLink"
      id="UseMobileView"
      class="Modal_backdrop Modal_backdrop--over-view"
    >
      <div class="h-100">
        <Transition name="fade">
          <section class="p-4 w-100 h-100 ecod-gradient-background">
            <Logo
              class="mb-6"
              :variant="logoKeyName.CONTRAST_LOGO"
            />
            <h2 class="mb-3 emobg-title-xxl emobg-color-secondary-dark">
              {{ $t('use_mobile.title') }}
            </h2>

            <p class="mb-3 emobg-body-m-regular emobg-color-white">
              {{ $t('use_mobile.description') }}
            </p>

            <div class="d-flex">
              <a :href="links.ios">
                <img
                  :src="icons.DownloadAppStoreDefault"
                  class="mr-3"
                  alt="iOS"
                >
              </a>
              <a :href="links.android">
                <img
                  :src="icons.DownloadGooglePlayDefault"
                  alt="Android"
                >
              </a>
            </div>
          </section>
        </Transition>
      </div>
    </aside>
  </Transition>
</template>

<script>
import { getValue } from '@emobg/web-utils';

// Images
import DownloadAppStoreDefault from '@/assets/images/icons/download-app-store-default.svg';
import DownloadAppStoreHover from '@/assets/images/icons/download-app-store-hover.svg';
import DownloadGooglePlayDefault from '@/assets/images/icons/download-google-play-default.svg';
import DownloadGooglePlayHover from '@/assets/images/icons/download-google-play-hover.svg';
import Logo from '@/components/Logo/Logo';

// Constants
import { LOGOS_KEYNAMES } from '@/constants/assetsRepository';
import { useRoute } from 'vue-router/composables';
import { ANDROID, IOS } from './constants/appLinks.const';

export default {
  name: 'UseMobile',
  components: {
    Logo,
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  computed: {
    isResetPassword() {
      return /reset-password/.test(this.route.fullPath);
    },
    isTariffs() {
      return /tariffs/.test(this.route.fullPath);
    },
    isLegal() {
      return /legal/.test(this.route.fullPath);
    },
    isSignUp() {
      return /signup|sign-up/.test(this.route.fullPath);
    },
    requestByMobile() {
      return getValue(this.route, 'query.mobile', false);
    },
    showBackdropWithAppsLink() {
      return !getValue(this.route, 'meta.responsive', false)
        && !this.isResetPassword
        && !this.isTariffs
        && !this.isLegal
        && !this.isSignUp;
    },
  },
  created() {
    this.icons = {
      DownloadAppStoreDefault,
      DownloadAppStoreHover,
      DownloadGooglePlayDefault,
      DownloadGooglePlayHover,
    };
    this.links = {
      android: ANDROID,
      ios: IOS,
    };
    this.logoKeyName = LOGOS_KEYNAMES;
  },
};

</script>
<style lang="scss">
.Modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 280;
  width: 100%;
  height: 100%;
  overflow: auto;

  &--over-view {
    z-index: 100000;
  }
}
</style>
